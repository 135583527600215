(($) ->

  wowAnimation = ->
    if $('.wow').hasClass('animated')
      $(this).removeClass('animated')
      $(this).removeAttr('style')
      new WOW().init()
    wow = new WOW(
      {
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: true,
        live: true,
        callback: (box) ->,
        scrollContainer: null
      }
    )
    wow.init();



  wowDurationDelay = (duration = .2, delay = 0, element = '', animate = 'slideInDown', infinite = false, type = 'wow', inloop = false) ->
    elements = $(element)
    elements.addClass(type + ' ' + animate)
    count = elements.length

    if inloop == true
      for i in [0..count]
        elements.eq(i).attr("data-wow-duration", (i+1) * duration + "s").attr("data-wow-delay", delay + "s")
        if infinite
          elements.eq(i).attr("data-wow-iteration", "infinite")
    else
      elements.attr("data-wow-duration", (i+1) *  duration + "s").attr("data-wow-delay", delay + "s")

    return

#  wowDurationDelay(.5, 0, '.section', 'fadeInUp',false,'wow')
#  wowDurationDelay(.25, .1, '.product', 'fadeInUp',false,'wow',true)
#  wowDurationDelay(.5, .5, '.award', 'zoomIn',false,'wow',true)
#  wowDurationDelay(.25, 0, '.alert', 'fadeInUp',false,'animated')



  resizeTextarea = ->
    $('textarea').each ->
      this.setAttribute('style', 'height:' + (this.scrollHeight) + 'px;overflow-y:hidden;')
      .on('input', ->
      if this.scrollHeight > 62
        this.style.height = 'auto'
        this.style.height = (this.scrollHeight-24) + 'px'
      else
        this.style.height = 62 + 'px'
    )
    return


  scrolleToID = ->
    $("a").click ->
      if $(this).attr("href")[0] == "#"
        $("html,body").animate({
          scrollTop: $($(this).attr("href")).offset().top - 100
        }, 500)
        event.preventDefault()

  scrolleToID()

  validOnSubmit = () ->
    $('.error-label-valid').hide()
    $('form.form-validate').submit (evt) ->
      obj = evt.originalEvent.target
      $.each obj, (key, value) ->
        if !obj[key].validity.valid
          evt.preventDefault()
          if $(this).hasClass('form-input')
            $(this).next('.error-label-valid').text(obj[key].validationMessage).show()

          if $(this).hasClass('input-checkbox')
            $(this).addClass('error-border')
            $(this).closest('.checkbox-style').find('.error-label-valid').text(obj[key].validationMessage).show()
        else
          return true
    return
  validOnSubmit()

  formReqValidChange = (el) ->
    $('.error-label-valid').hide()
    el.change (e) ->
      if e.originalEvent
        obj = e.originalEvent.target
        if !obj.validity.valid
          if $(this).hasClass('form-input')
            $(this).next('.error-label-valid').text(obj.validationMessage).show()
          if $(this).hasClass('input-checkbox')
            $(this).addClass('error-border')
            $(this).closest('.checkbox-style').find('.error-label-valid').text(obj[key].validationMessage).show()
        else

          if $(this).hasClass('form-input')
            $(this).next('.error-label-valid').hide()
          if $(this).hasClass('input-checkbox')
            $(this).removeClass('error-border')
            $(this).closest('.checkbox-style').find('.error-label-valid').text('').hide()
        return
      else
        return

  formReqValidChange($('form.form-validate input,form.form-validate textarea'))


  notme = $('[data-require-type="notme"]');

  formCollpaseRequired = (element, req) ->
    console.log req
    element.next('.error-label-valid').hide()
    notme.prop('required', req)

  formTypeChange = () ->
    $('input[name="type"]').change ->
      status = $(this).prop('checked')
      formCollpaseRequired(notme, !status)
  formTypeChange()

  removeAlert = (el) ->
    el.remove()

  alertClose = ->
    alert = $('.alerts .alert')
    count = 0;
    alert.click ->
      count++
      removeAlert($(this))
      if alert.length == count
        $('.alerts').remove()
    if alert.length > 0
      setTimeout(() ->
        removeAlert($('.alerts'))
      , 15000)


  alertClose()


  setAttachmentsList = () ->
    $('input:file').change (e) ->
      element = $('#form-input-files-list')
      element.html('')
      $.each(e.target.files, (key, value) ->
        element.append('<span class="input-file-name block">'+value.name+'</span>')
      )
  setAttachmentsList()


  setSelectedCategory = () ->
    $('.form-select-category').click ->
      text = $(this).text()
      $(this).parent().parent().find('button').text(text)
      $(this).parent().parent().find('input').prop('value',text)
  setSelectedCategory()

  return) jQuery



setInputMasks = (className = '', object = {}) ->
  document.addEventListener("DOMContentLoaded", () ->
    maskClassNames = document.getElementsByClassName(className)
    for maskClassName, i in maskClassNames
      dateMask = new IMask(
        maskClass = maskClassName, object
      ).on('accept', () ->

      )
  )

WHReadCookie = (name) ->
  nameEQ = name + "="
  ca = document.cookie.split(';')
  c = ca[1]
  if c
    while c.charAt(0) == ' '
      c = c.substring(1, c.length)
      if c.indexOf(nameEQ) == 0
        return c.substring(nameEQ.length, c.length)
  return 'F'

cookiesAlertCheck = ->
  if WHReadCookie('cookies_accepted') == 'T'
    $('.cookies-alert').hide()
    $('.cookies-alert').remove()
cookiesAlertCheck()

cookiesAlert = () ->
  $('.cookies-alert button.cookies-alert-button').click ->
    $('.cookies-alert').toggleClass('is-close')
    WHCreateCookie('cookies_accepted', 'T', 30)

    setTimeout( ->
      $('.cookies-alert').hide()
      $('.cookies-alert').remove()
    , 1000)


cookiesAlert()




WHCreateCookie = (name, value, days) ->
  date = new Date()
  date.setTime(date.getTime() + (days*24*60*60*1000))
  expires = "; expires=" + date.toGMTString()
  document.cookie = name+"="+value+expires+"; path=/"
















emailPattern = /// ^ #begin of line
   ([\w.-]+)         #one or more letters, numbers, _ . or -
   @                 #followed by an @ sign
   ([\w.-]+)         #then one or more letters, numbers, _ . or -
   \.                #followed by a period
   ([a-zA-Z.]{2,6})  #followed by 2 to 6 letters or periods
   $ ///i #end of line and ignore case

setInputMasks('mask-date', {mask: Date, lazy: true})
setInputMasks('mask-zip', {mask: '00-000', lazy: true})
setInputMasks('mask-email', {mask: '', lazy: true})
setInputMasks('mask-phone', {mask: '+48 000-000-000', lazy: true})
setInputMasks('mask-number', {mask: Number, min: 0, max: 1000, lazy: true})
